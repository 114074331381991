import React from "react"
import * as styles from "./MiniNavigation.module.scss"

export default function MiniNavigation({ items, title }) {
  const [refIndex, setRefIndex] = React.useState({})
  const h2Headings = items
    .filter(item => item.depth === 2)
    .map(item => item.value)

  const handleRef = i => ref => {
    if (!ref || refIndex[i]) return
    setRefIndex({ ...refIndex, [i]: ref })
  }

  const handleScroll = heading => () => {
    ;[...document.getElementsByTagName("h2")]
      .find(el => el.textContent === heading)
      .scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      })
  }

  React.useEffect(() => {
    const io = new IntersectionObserver(
      entries => {
        const max = entries.reduce(
          (acc, entry) => {
            if (entry.intersectionRatio >= acc.intersectionRatio) acc = entry
            return acc
          },
          { intersectionRatio: 0 }
        )

        if (max.intersectionRatio > 0) {
          Object.keys(refIndex).map(k => {
            if (refIndex[k]) refIndex[k].classList.remove("active")
          })

          const newIndex =
            max.intersectionRatio > 0
              ? h2Headings.findIndex(h => h === max.target.textContent)
              : -1

          if (refIndex[newIndex]) {
            refIndex[newIndex].classList.add("active")
          }
        }
      },
      {
        threshold: [0],
        rootMargin: "-200px",
      }
    )

    h2Headings.forEach((heading, i) => {
      io.observe(
        [...document.getElementsByTagName("h2")].find(
          el => el.textContent === heading
        )
      )
    })

    return () => {
      io.disconnect()
    }
  }, [refIndex, h2Headings])

  if (h2Headings.length === 0) return null

  return (
    <div className={styles.miniNavigation}>
      <div className={styles.miniNavigationTitle}>THIS PAGE</div>
      {h2Headings.map((heading, i) => (
        <div
          tabIndex="0"
          role="button"
          ref={handleRef(i)}
          key={`${heading}-${i}`}
          onClick={handleScroll(heading)}
          className={`${styles.miniNavigationItem}`}
        >
          {heading}
        </div>
      ))}
    </div>
  )
}
