import React from "react"
import { graphql } from "gatsby"
import Layout from "./components/Layout"
import MiniNavigation from "./components/MiniNavigation"
import Breadcrumbs from "./components/Breadcrumbs"
import SupportFooter from "./components/SupportFooter"
import * as styles from "./Doc.module.scss"

import Header from "./components/Header"
import Navigation from "./components/Navigation"

require("prism-themes/themes/prism-shades-of-purple.css")

function BrowserLayout({ children, props = {} }) {
  const [isNavigationOpen, setIsNavigationOpen] = React.useState(false)
  const { markdownRemark = {} } = props.data || {}
  const { frontmatter = {} } = markdownRemark
  const [videoIndex, setVideoIndex] = React.useState(0)

  React.useEffect(() => {
    const videos = [
      "/videos/graph.webm",
      "/videos/cms.webm",
      "/videos/api.webm",
    ]
    const video = document.getElementById("video")
    if (!video) return
    video.onended = () => {
      setVideoIndex(videoIndex => {
        const nextIndex = videoIndex === videos.length - 1 ? 0 : videoIndex + 1
        video.src = videos[nextIndex]
        return nextIndex
      })
    }
  }, [videoIndex])

  return (
    <div>
      {/* <Header /> */}
      <div className={styles.doc}>
        <Navigation
          isOpen={isNavigationOpen}
          activeSlug={frontmatter.slug}
          items={props.pageContext.navigation}
        />
        {children}
        <div
          tabIndex="0"
          role="button"
          onClick={() => setIsNavigationOpen(!isNavigationOpen)}
          className={styles.docMobileNavButton}
        >
          <svg
            width="100pt"
            height="100pt"
            version="1.1"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="m72.074 33.602c0.17578 0.023438 0.21875 0.023438 0.39062 0.066407 0.44922 0.11719 0.85938 0.37109 1.168 0.71875 0.17578 0.19922 0.31641 0.42969 0.41406 0.67578s0.15625 0.50781 0.16797 0.77344c0.035156 0.875-0.43359 1.7188-1.1992 2.1484-0.23438 0.12891-0.48438 0.21875-0.74609 0.26172-0.17578 0.03125-0.21875 0.027344-0.39844 0.035156h-43.75c-0.17578-0.007812-0.22266-0.003906-0.39453-0.035156-0.46094-0.078125-0.89062-0.29688-1.2266-0.61719-0.19141-0.18359-0.35156-0.39844-0.46875-0.63672-0.12109-0.23438-0.19922-0.49219-0.23438-0.75781-0.09375-0.73047 0.17188-1.4805 0.70312-1.9922 0.14453-0.13672 0.30469-0.25781 0.47656-0.35547 0.23438-0.12891 0.48438-0.21875 0.75-0.26172 0.17188-0.03125 0.21875-0.027344 0.39453-0.035156h43.75c0.066406 0.003906 0.13281 0.007812 0.19922 0.007812z" />
              <path d="m72.074 47.664c0.17578 0.023438 0.21875 0.023438 0.39062 0.066407 0.44922 0.11719 0.85938 0.37109 1.168 0.71875 0.17578 0.19922 0.31641 0.42969 0.41406 0.67578 0.32812 0.8125 0.16406 1.7656-0.41406 2.4219-0.17578 0.19922-0.38281 0.36719-0.61719 0.5-0.23438 0.12891-0.48438 0.21875-0.74609 0.26172-0.17578 0.03125-0.21875 0.027344-0.39844 0.035156h-43.75c-0.17578-0.007812-0.22266-0.003906-0.39453-0.035156-0.26172-0.042969-0.51562-0.13281-0.75-0.26172-0.64062-0.35938-1.0859-1.0195-1.1797-1.75-0.023437-0.19922-0.023437-0.39844 0-0.59766 0.035156-0.26172 0.11328-0.51953 0.23438-0.75781 0.11719-0.23828 0.27734-0.45312 0.46875-0.63672 0.33594-0.32031 0.76562-0.53906 1.2266-0.61719 0.17188-0.03125 0.21875-0.027344 0.39453-0.035156h43.75c0.066406 0.003906 0.13281 0.007812 0.19922 0.007812z" />
              <path d="m72.074 61.727c0.17578 0.023438 0.21875 0.023438 0.39062 0.066407 0.44922 0.11719 0.85938 0.37109 1.168 0.71875 0.57813 0.65625 0.74219 1.6094 0.41406 2.4219-0.097656 0.24609-0.24219 0.47656-0.41406 0.67578-0.17578 0.19922-0.38281 0.36719-0.61719 0.5-0.23438 0.12891-0.48438 0.21875-0.74609 0.26172-0.17578 0.03125-0.21875 0.027344-0.39844 0.035156h-43.75c-0.17578-0.007812-0.22266-0.003906-0.39453-0.035156-0.46094-0.078125-0.89062-0.29688-1.2266-0.61719-0.14453-0.13672-0.26953-0.29297-0.375-0.46484-0.38672-0.625-0.45312-1.418-0.17969-2.1016 0.10156-0.24609 0.24219-0.47656 0.41406-0.67578 0.17578-0.19922 0.38281-0.36719 0.61719-0.5 0.23438-0.12891 0.48438-0.21875 0.75-0.26172 0.17188-0.03125 0.21875-0.027344 0.39453-0.035156h43.75c0.066406 0.003906 0.13281 0.007812 0.19922 0.007812z" />
            </g>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default function DocTemplate({ data, pageContext }) {
  const { markdownRemark } = data
  const { frontmatter, html, headings, excerpt } = markdownRemark
  const previousLink = pageContext.previous
    ? `/docs${pageContext.previous.frontmatter.slug}`
    : null

  const nextLink = pageContext.next
    ? `/docs${pageContext.next.frontmatter.slug}`
    : null

  return (
    <BrowserLayout props={{ data, pageContext }}>
      <Layout title={frontmatter.title} description={excerpt}>
        <div className={styles.docContent}>
          <Breadcrumbs slug={frontmatter.slug} title={frontmatter.title} />
          <div className={styles.docContentBody}>
            <h1>{frontmatter.title}</h1>
            <MiniNavigation title={frontmatter.title} items={headings} />
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
            <SupportFooter title={frontmatter.title} />
            <div className={styles.docQuickLinks}>
              {pageContext.previous && (
                <a
                  href={previousLink}
                  className={`${styles.docQuickLink} ${styles.previous}`}
                >
                  <div className={styles.docQuickLinkTitle}>PREVIOUS</div>
                  <div className={styles.docQuickLinkSubtitle}>
                    ← {pageContext.previous.frontmatter.title}
                  </div>
                </a>
              )}
              {pageContext.next && (
                <a
                  href={nextLink}
                  className={`${styles.docQuickLink} ${styles.next}`}
                >
                  <div className={styles.docQuickLinkTitle}>NEXT</div>
                  <div className={styles.docQuickLinkSubtitle}>
                    {pageContext.next.frontmatter.title} →
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </BrowserLayout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt
      headings {
        depth
        value
      }
      frontmatter {
        slug
        title
      }
    }
  }
`
